import { Navigate, Outlet, useLocation } from "react-router-dom";
import { privateRoutes, publicRoutes } from "../_helpers/constants/routes";
import { useSelector } from "react-redux";
import { RootState } from "../_store";
import PublicLayout from "../layout/dashboard/PublicLayout";

export default function PublicRoutes() {
  const { pathname } = useLocation();
  const { isAuthenticated } = useSelector((state: RootState) => state?.auth);

  if (isAuthenticated) {
    return <Navigate to={privateRoutes.DASHBOARD} />;
  }
  if (pathname === privateRoutes.INDEX) {
    return <Navigate to={publicRoutes.LOGIN} />;
  }
  return (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
}
