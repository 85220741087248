import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../_helpers/axiosConfig";

const baseUrl = `${process.env.REACT_APP_API_BASE_URI}/user/current`;

export const fetchUserData = createAsyncThunk("user/current", async () => {
  try {
    const data = await axiosApiInstance.get(baseUrl);
    return data?.data;
  } catch (err: any) {
    console.error(err);
  }
});
