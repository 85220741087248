import { Box, Card } from "@mui/material";

const LoginWrapper = ({ children, ...other }: any) => (
  <Card>
    <Box
      sx={{
        maxWidth: { xs: 400, lg: 475 },
        margin: { xs: 1, md: 2.5 },
        "& > *": {
          flexGrow: 1,
          flexBasis: "50%",
        },
      }}
      {...other}
    >
      <Box sx={{ p: { xs: 2, sm: 3, xl: 4 } }}>{children}</Box>
    </Box>
  </Card>
);

export default LoginWrapper;
