import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

function createInitialState(): any {
  const a_token = Cookies.get("accessToken");
  const r_token = Cookies.get("jwt");
  const accessToken = a_token ? a_token : null;
  const refreshToken = r_token ? r_token : null;
  const isAuthenticated = a_token ? true : false;
  // const isAuthenticated = true;
  const isLoading = false;
  return { accessToken, refreshToken, isAuthenticated };
}

const baseUrl = `${process.env.REACT_APP_API_BASE_URI}`;
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder
      .addCase(authActions.login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(authActions.login.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

function createReducers() {
  return {
    setAuth,
  };

  function setAuth(state: any, action: PayloadAction<any>) {
    state.accessToken = action.payload.accessToken;
    state.refreshToken = action.payload.refreshToken;
    state.isAuthenticated = action.payload.isAuthenticated;
  }
}

function createExtraActions() {
  return {
    login: login(),
    logout: logout(),
    register: registerUser(),
    getRefreshToken: getRefreshToken(),
  };

  function registerUser() {
    return createAsyncThunk(
      "auth/register",
      async function ({ username, password, useremail }: any, { dispatch }) {
        const requestData = {
          user_name: username,
          user_pass: password,
          user_email: useremail,
        };
        try {
          const data = await axios.post(`${baseUrl}/auth/login`, requestData);
          // dispatch(
          //   authActions.setAuth({
          //     isAuthenticated: true,
          //     accessToken: accessToken,
          //     refreshToken: refreshToken,
          //   })
          // );
          // Cookies.set("accessToken", accessToken);
          // Cookies.set("jwt", refreshToken);
          return data;
        } catch (err: any) {
          console.error(err.message);
        }
      }
    );
  }

  function login() {
    return createAsyncThunk(
      "auth/login",
      async function ({ username, password }: any, { dispatch }) {
        const requestData = {
          username,
          password,
        };
        try {
          const {
            data: { accessToken, refreshToken },
          } = await axios.post(`${baseUrl}/auth/login`, requestData);
          dispatch(
            authActions.setAuth({
              isAuthenticated: true,
              accessToken: accessToken,
              refreshToken: refreshToken,
            })
          );
          Cookies.set("accessToken", accessToken);
          Cookies.set("jwt", refreshToken);
          return { accessToken, refreshToken };
        } catch (err: any) {
          console.error(err.message);
        }
      }
    );
  }

  function logout() {
    return createAsyncThunk("auth/logout", function (arg, { dispatch }) {
      dispatch(
        authActions.setAuth({ isAuthenticated: false, accessToken: null })
      );
      Cookies.remove("accessToken");
      localStorage.removeItem("persist:root");
    });
  }

  function getRefreshToken() {
    return createAsyncThunk("auth/refresh", async function (arg, { dispatch }) {
      try {
        const {
          data: { accessToken },
        } = await axios.post(`${baseUrl}/auth/refresh`, null, {
          withCredentials: true,
        });
        dispatch(
          authActions.setAuth({
            isAuthenticated: true,
            accessToken: accessToken,
          })
        );
        Cookies.set("accessToken", accessToken);
        return {
          data: { accessToken },
        };
      } catch (err: any) {
        console.error(err.message);
      }
    });
  }
}

export const authActions = { ...authSlice.actions, ...extraActions };
export default authSlice.reducer;
