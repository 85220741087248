import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Grid,
  Button,
  Box,
  Divider,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../_store";
import { authActions } from "../../_store/slice/auth.slice";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { showToast } from "../../_store/slice/toast.slice";
import { z } from "zod";
import { infer as Infer } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Iconify from "../../_components/Iconify";

interface IFormInput {
  username: string;
  password: string;
}

const formSchema = z.object({
  username: z.string().min(1, { message: "Username field cannot be empty." }),
  password: z
    .string()
    .nonempty({ message: "Password field cannot be empty." })
    .min(5, {
      message: "Password must be at least 5 characters.",
    }),
});
type Schema = Infer<typeof formSchema>;

export default function LoginForm() {
  const theme = useTheme<any>();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector((state: RootState) => state.auth);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Schema>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const submitForm: SubmitHandler<IFormInput> = ({
    username,
    password,
  }: Schema) => {
    return dispatch(authActions.login({ username, password })).then((res) => {
      if (res.payload) {
        navigate("/dashboard/app");
        dispatch(
          showToast({
            message: "Successfully logged in.",
            type: "success",
          })
        );
      } else {
        dispatch(
          showToast({
            message: "Something went wrong! Please try again.",
            type: "warning",
          })
        );
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <Stack spacing={3}>
          <Controller
            name="username"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                label="Username"
                error={Boolean(errors.username)}
                helperText={errors.username?.message}
                variant="outlined"
                inputRef={ref}
                {...field}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                inputRef={ref}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          // icon={
                          //   showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          // }
                          icon={"AcUnitOutlined"}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>

        <Box sx={{ mt: 2 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
            sx={{ my: 5 }}
          >
            Sign in
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}
