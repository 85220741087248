import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  fetchMailData,
  updateReadStatus,
} from "../action/connectWithUs.action";

interface connectWithUsDataState {
  data: any[];
  isLoading: boolean;
  error: string | null;
}

const initialState: connectWithUsDataState = {
  data: [],
  isLoading: false,
  error: null,
};

const connectWithUsData = createSlice({
  name: "mail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMailData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchMailData.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.isLoading = false;
          state.data = action.payload;
          state.error = null;
        }
      )
      .addCase(fetchMailData.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(
        updateReadStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.data = state.data.map((item) =>
            item.id === action.payload.id
              ? { ...item, status: action.payload.status }
              : item
          );
          state.error = null;
        }
      );
  },
});

export default connectWithUsData.reducer;
