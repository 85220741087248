import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  fetchSocialLinkData,
  updateSocialLinkData,
} from "../action/socialLinks.action";
import { gridFilterActiveItemsLookupSelector } from "@mui/x-data-grid";

interface socialLinksData {
  data: any;
  isLoading: boolean;
  error: boolean;
}

const initialState: socialLinksData = {
  data: {},
  isLoading: false,
  error: true,
};

const socialLinksSlice = createSlice({
  name: "socialLinks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSocialLinkData.pending, (state) => {
        state.isLoading = true;
        state.data = {};
        state.error = false;
      })
      .addCase(
        fetchSocialLinkData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.data = action.payload;
          state.error = false;
        }
      )
      .addCase(
        fetchSocialLinkData.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = true;
        }
      )
      .addCase(
        updateSocialLinkData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.data = action.payload;
          state.error = false;
        }
      )
      .addCase(
        updateSocialLinkData.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = true;
        }
      );
  },
});

export default socialLinksSlice.reducer;
