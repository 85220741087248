// import { useSnackbar } from 'notistack';
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import { Box, Divider, Typography, Stack, MenuItem } from "@mui/material";
import { PATH_DASHBOARD, PATH_AUTH } from "../../../routes/paths";
import useIsMountedRef from "../../../_helpers/hooks/useIsMountedRef";
import { IconButtonAnimate } from "../../../_components/animate";
import MyAvatar from "../../../_components/MyAvatar";
import MenuPopover from "../../../_components/MenuPopover";
import { authActions } from "../../../_store/slice/auth.slice";
import { showToast } from "../../../_store/slice/toast.slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../_store";

const MENU_OPTIONS = [
  {
    label: "Home",
    linkTo: "/",
  },
  {
    label: "Profile",
    linkTo: "/",
  },
  {
    label: "Settings",
    linkTo: "/",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  // const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  // const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<any>(null);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    dispatch(authActions.logout()).then(() => {
      dispatch(
        showToast({
          message: "Successfully logged out.",
          type: "success",
        })
      );
      navigate("/auth/login");
    });
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme: any) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            displayName
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            email
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
