// routes
import { RouterProvider } from "react-router-dom";
import ScrollToTop from "./_components/ScrollToTop";
import ThemeColorPresets from "./_components/ThemeColorPresets";
import { MotionLazyContainer } from "./_components/animate";
import ThemeProvider from "./theme";
import { appRoutes } from "./routes";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <MotionLazyContainer>
          <ScrollToTop />
          <RouterProvider router={appRoutes} />
        </MotionLazyContainer>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
