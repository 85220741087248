import { Grid, Container, Typography } from "@mui/material";
import Page from "../../../_components/Page";

export function index() {
  return (
    <Page title="Dashboard">
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
      </Container>
    </Page>
  );
}
