import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { store } from "./_store";
import "simplebar-react/dist/simplebar.min.css";
import { RouterProvider } from "react-router-dom";
import { appRoutes } from "./routes";
import CustomizedToast from "./_components/Toast";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { SettingsProvider } from "./_helpers/contexts/SettingsContext";
import { CollapseDrawerProvider } from "./_helpers/contexts/CollapseDrawerContext";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

const root: any = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// const persist = createRoot(root);
let persistor = persistStore(store);

root.render(
  <>
    <React.StrictMode>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <Provider store={store}>
            <SettingsProvider>
              <CollapseDrawerProvider>
                {/* <CssBaseline /> */}
                <PersistGate loading={null} persistor={persistor}>
                  <App />
                </PersistGate>
                <CustomizedToast />
              </CollapseDrawerProvider>
            </SettingsProvider>
          </Provider>
        </StyledEngineProvider>
      </HelmetProvider>
    </React.StrictMode>
  </>
);
