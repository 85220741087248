import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../_helpers/axiosConfig";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_BASE_URI}/social/social-links`;

export const fetchSocialLinkData = createAsyncThunk(
  "socialLinks/get",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axiosApiInstance.get(baseUrl);
      return data?.data;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
);

export const updateSocialLinkData = createAsyncThunk(
  "socialLinks/update",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.put(
        `${baseUrl}/${data?.id}`,
        data
      );

      return response?.data?.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);
