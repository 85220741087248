import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../_store";
import { hideToast } from "../_store/slice/toast.slice";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedToast() {
  const dispatch = useDispatch<AppDispatch>();
  const { message, type, showToast } = useSelector(
    (state: RootState) => state.toast
  );

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideToast({ showToast: false }));
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={showToast}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={type}
          sx={{
            width: "100%",
            borderRadius: (theme) => {
              return theme?.shape?.borderRadius;
            },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
