import { Helmet } from "react-helmet-async";
import { styled, alpha } from "@mui/material/styles";
import { Typography, Stack, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import LoginWrapper from "./LoginWrapper";
import LoginInner from "./LoginInner";
import Logo from "../../_components/Logo";
import useResponsive from "../../_helpers/hooks/useResponsive";

const AuthWrapper = styled("div")(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.light, 0.7),
  minHeight: "100vh",
}));

export default function LoginCard() {
  const isDesktop = useResponsive("up", "xl");

  return (
    <>
      <Helmet>
        <title> Login | Spark Education and Consultancy </title>
      </Helmet>

      <AuthWrapper>
        {/* <Grid
          container
          direction="column"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        > */}
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <LoginWrapper>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 3 }}>
                    <Link to="#">
                      <Logo disabledLink={true} />
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item>
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Typography
                            gutterBottom
                            variant={"h3"}
                            sx={{
                              color: (theme: any) => theme.palette.primary.dark,
                              textAlign: "center",
                            }}
                          >
                            Hi, Welcome Back
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <LoginInner />
                  </Grid>
                </Grid>
              </LoginWrapper>
            </Grid>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </AuthWrapper>
    </>
  );
}
