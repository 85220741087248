import React from "react";
import Page from "../../../_components/Page";
import { Container, Typography } from "@mui/material";

const SiteSettings = () => {
  return (
    <Page title="Site Settings">
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          SiteSettings
        </Typography>
      </Container>
    </Page>
  );
};

export default SiteSettings;
