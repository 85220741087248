import axios from "axios";
import { store } from "../_store";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { authActions } from "../_store/slice/auth.slice";
import Cookies from "js-cookie";

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async (req: any) => {
    var a_authToken = Cookies.get("accessToken");
    const r_authToken = Cookies.get("jwt");
    if (a_authToken) {
      req.headers.Authorization = `Bearer ${a_authToken}`;
    }

    // Check expirey of accessToken
    const a_token: any = jwt_decode(`${a_authToken}`);
    const isExpired = dayjs.unix(a_token?.exp).diff(dayjs()) < 1;

    // Check expirey of refreshToken
    const r_token: any = jwt_decode(`${r_authToken}`);
    const r_token_isExpired = dayjs.unix(r_token?.exp).diff(dayjs()) < 1;
    if (r_token_isExpired) {
      return store.dispatch(
        authActions.setAuth({
          isAuthenticated: false,
          accessToken: null,
          refreshToken: null,
        })
      );
    }

    if (!isExpired) return req;
    try {
      const response: any = await store.dispatch(authActions.getRefreshToken());

      Cookies.set("accessToken", response?.payload?.data?.accessToken);
      a_authToken = response?.payload?.data?.accessToken;

      req.headers.Authorization = `Bearer ${a_authToken}`;
    } catch (error) {
      console.error("Error getting refresh token:", error);
    }

    return req;
  },
  function (err) {
    return Promise.reject(err);
  }
);

axiosApiInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export { axiosApiInstance };
