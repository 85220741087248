function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOT = "/";
const ROOT_AUTH = "/auth";
const ROOT_DASHBOARD = "/dashboard";

export const privateRoutes = {
  INDEX: ROOT,
  DASHBOARD: ROOT_DASHBOARD,
  GENERAL: {
    HOME: path(ROOT_DASHBOARD, "/app"),
  },
  SETTINGS: {
    ROOT: path(ROOT_DASHBOARD, "/settings"),
    SITE_SETTINGS: path(ROOT_DASHBOARD, "/settings/site-settings"),
    SOCIAL_SETTINGS: path(ROOT_DASHBOARD, "/settings/social-settings"),
  },
  MAIL: {
    ROOT: path(ROOT_DASHBOARD, "/inbox"),
  },
};

export const publicRoutes = {
  ANY: "*",
  404: "404",
  LOGIN: path(ROOT_AUTH, "/login"),
  REGISTER: path(ROOT_AUTH, "/register"),
};
