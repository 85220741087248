import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchUserData } from "../action/currentUser.action";

interface currentUserDataState {
  currentUserData: any;
  isLoading: boolean;
  error: string | null;
}

const initialState: currentUserDataState = {
  currentUserData: {},
  isLoading: false,
  error: null,
};

const currentUserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.isLoading = true;
        state.currentUserData = {};
        state.error = "is Pending";
      })
      .addCase(fetchUserData.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.currentUserData = action.payload;
        state.error = "is Fulfilled";
      })
      .addCase(
        fetchUserData.rejected,
        (state, { payload }: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = "is Rejected";
        }
      );
  },
});

export default currentUserSlice.reducer;
