import { Box } from "@mui/material";
import { IconButtonAnimate } from "../../../_components/animate";
import Iconify from "../../../_components/Iconify";
import { NAVBAR } from "../../../config";

interface Props {
  collapseClick: boolean;
  onToggleCollapse: any;
  sx?: object | React.CSSProperties;
  isCollapse: any;
}

export default function CollapseButton({
  onToggleCollapse,
  collapseClick,
  isCollapse,
}: Props) {
  return (
    <IconButtonAnimate onClick={onToggleCollapse} color="primary" size="large">
      <Box
        sx={{
          lineHeight: 0,
          borderWidth: 1,
          backgroundColor: "#fff",
          borderStyle: "dashed",
          borderRadius: 100,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),
          ...(collapseClick && {
            transform: "rotate(180deg)",
          }),
        }}
      >
        {icon}
      </Box>
    </IconButtonAnimate>
  );
}

// ----------------------------------------------------------------------

const icon = <Iconify icon={"AcUnitOutlined"} width={20} height={20} />;
