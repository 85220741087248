import * as MaterialIcon from "@mui/icons-material";

type IconType = keyof typeof MaterialIcon;

interface Props {
  icon: IconType;
  sx?: object | React.CSSProperties;
  width?: number;
  height?: number;
}

export default function Iconify({ icon, sx, ...other }: Props) {
  const Icon = MaterialIcon[icon];
  return <Icon sx={{ ...sx }} {...other} />;
}
