import { useEffect, useLayoutEffect, useState } from "react";
import { Link as useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Typography,
  TextField,
  InputAdornment,
  Stack,
} from "@mui/material";
import useSettings from "../../../_helpers/hooks/useSettings";
import useTabs from "../../../_helpers/hooks/useTabs";
import Page from "../../../_components/Page";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from "../../../_components/table";
import Iconify from "../../../_components/Iconify";
import useTable, {
  emptyRows,
  getComparator,
} from "../../../_helpers/hooks/useTable";
import Scrollbar from "../../../_components/Scrollbar";
import MailingListTableRow from "./MailingListTableRow";
import fakeData from "./__fake.json";
import { AppDispatch, RootState } from "../../../_store";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMailData,
  updateReadStatus,
} from "../../../_store/action/connectWithUs.action";

const STATUS_OPTIONS = ["all", "Unread", "Read"];

const TABLE_HEAD = [
  { id: "name", label: "Full Name", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "phone", label: "Phone No.", align: "left" },
  { id: "message", label: "Message", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "" },
];

// ----------------------------------------------------------------------

const MailingList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { data, isLoading } = useSelector((state: RootState) => state?.mail);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const [tableData, setTableData] = useState<any[]>([]);

  const [filterName, setFilterName] = useState("");

  // const [filterRole, setFilterRole] = useState("all");

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs("all");

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleFilterName = (filterName: any) => {
    setFilterName(filterName);

    setPage(0);
  };

  // const handleFilterRole = (event: any) => {
  //   setFilterRole(event.target.value);
  // };

  const handleDeleteRow = (id: any) => {
    const deleteRow = tableData.filter((row: any) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleDeleteRows = (selected: any) => {
    const deleteRows = tableData.filter(
      (row: any) => !selected.includes(row.id)
    );
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id: any) => {
    console.log("id", id);
  };

  const handleUpdateStatus = (id: string) => {
    dispatch(updateReadStatus(id));
    dispatch(fetchMailData());
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
  });

  useEffect(() => {
    dispatch(fetchMailData());
  }, [dispatch]);

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered?.length && !!filterName) ||
    (!dataFiltered?.length && !!filterStatus);

  return (
    <Page title="Inbox">
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Inbox
        </Typography>
        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: "background.neutral" }}
          >
            {STATUS_OPTIONS?.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <Stack spacing={2} sx={{ py: 2.5, px: 3 }}>
            <TextField
              fullWidth
              value={filterName}
              onChange={(event) => handleFilterName(event.target.value)}
              placeholder="Search by name..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {/* <Iconify
                      icon={"eva:search-fill"}
                      sx={{ color: "text.disabled", width: 20, height: 20 }}
                    /> */}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: "relative" }}>
              {selected?.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected?.length}
                  rowCount={tableData?.length}
                  onSelectAllRows={(checked: any) =>
                    onSelectAllRows(
                      checked,
                      tableData?.map((row: any) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton
                        color="primary"
                        // onClick={() => handleDeleteRows(selected)}
                      >
                        {/* <Iconify icon={"eva:trash-2-outline"} /> */}
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? "small" : "medium"}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                  onSelectAllRows={(checked: any) =>
                    onSelectAllRows(
                      checked,
                      tableData?.map((row: any) => row.id)
                    )
                  }
                />
                <TableBody>
                  {dataFiltered
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row: any) => (
                      <MailingListTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        // onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRowStatus={() => handleUpdateStatus(row.id)}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData?.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: "relative" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: "absolute" } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: any) {
  const stabilizedThis = tableData?.map((el: any, index: any) => [el, index]);

  stabilizedThis?.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis?.map((el: any) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item: any) =>
        item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
      // console.log("item", item)
    );
  }

  if (filterStatus !== "all") {
    tableData = tableData.filter(
      (item: any) => item?.status?.display_name === filterStatus
    );
  }

  return tableData;
}

export default MailingList;
