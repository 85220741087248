import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../_helpers/axiosConfig";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_BASE_URI}/mail/inbox`;

export const fetchMailData = createAsyncThunk(
  "mail/getAll",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axiosApiInstance.get(baseUrl);
      return data?.data;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
);

export const deleteMailData = createAsyncThunk(
  "mail/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.put(
        `${baseUrl}/${data?.id}`,
        data?.data
      );
      console.log("first==>", response.data.data);
      return response?.data?.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);

export const updateReadStatus = createAsyncThunk(
  "mail/updateStatus",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.put(`${baseUrl}/${id}`);
      return response?.data?.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);
