import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Checkbox,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
} from "@mui/material";
import Iconify from "../../../_components/Iconify";
import Label from "../../../_components/Label";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { TableMoreMenu } from "../../../_components/table";

interface MailingListTableRow {
  row?: any;
  selected?: any;
  onEditRowStatus?: any;
  onSelectRow?: any;
  onDeleteRow?: any;
}

export default function MailingListTableRow({
  row,
  selected,
  onEditRowStatus,
  onSelectRow,
  onDeleteRow,
}: MailingListTableRow) {
  const theme = useTheme();

  const { name, avatarUrl, phone, email, message, status } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event: any) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell>
        {/* <Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} /> */}
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>

      <TableCell align="left">{email}</TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        {phone}
      </TableCell>

      <TableCell align="left">
        {message.slice(10)} ...
        {/* <Iconify
          icon={isVerified ? "eva:checkmark-circle-fill" : "eva:clock-outline"}
          sx={{
            width: 20,
            height: 20,
            color: "success.main",
            ...(!isVerified && { color: "warning.main" }),
          }}
        /> */}
      </TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={(status?.system_name === "unread" && "error") || "success"}
          sx={{ textTransform: "capitalize" }}
        >
          {status?.display_name}
        </Label>
      </TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  // handleCloseMenu();
                }}
              >
                <AutoStoriesIcon />
                Read More
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRowStatus();
                  handleCloseMenu();
                }}
              >
                <MarkEmailReadIcon />
                Mark as Read
              </MenuItem>
              <MenuItem
                onClick={() => {
                  // onDeleteRow();
                  // handleCloseMenu();
                }}
                sx={{ color: "error.main" }}
              >
                <DeleteOutlineIcon />
                Delete
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
