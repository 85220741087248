import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
// import useSettings from '../../hooks/useSettings';
// import useResponsive from '../../hooks/useResponsive';
// import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import NavbarVertical from './navbar/NavbarVertical';
import useSettings from '../../_helpers/hooks/useSettings';
import useResponsive from '../../_helpers/hooks/useResponsive';
import useCollapseDrawer from '../../_helpers/hooks/useCollapseDrawer';
import DashboardHeader from './header';

const MainStyle:any = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }:any) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }: any) {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);


  return (
    <Box
    sx={{
      display: { lg: 'flex' },
      minHeight: { lg: 1 },
    }}
  >
    <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

    <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

    <MainStyle collapseClick={collapseClick}>
      <Outlet />
    </MainStyle>
  </Box>
  );
}
