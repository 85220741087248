import { Controller, useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import {
  Stack,
  Card,
  InputAdornment,
  Container,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../_components/Iconify";
import { FormProvider, RHFTextField } from "../../../_components/hook-form";
import Page from "../../../_components/Page";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../_store";
import { Fragment, useEffect, useState } from "react";
import {
  fetchSocialLinkData,
  updateSocialLinkData,
} from "../../../_store/action/socialLinks.action";
import { showToast } from "../../../_store/slice/toast.slice";

export default function SocialSettings({}) {
  const dispatch = useDispatch<AppDispatch>();

  const {
    data: socials,
    error,
    isLoading,
  } = useSelector((state: RootState) => state.socialLinks);

  useEffect(() => {
    dispatch(fetchSocialLinkData());
  }, [dispatch]);

  // useEffect(() => {
  //   if (error !== null) {
  //     dispatch(
  //       showToast({
  //         message: "Something! went wrong.",
  //         type: "warning",
  //       })
  //     );
  //   }
  // }, [error]);

  const SOCIAL_LINKS = [
    {
      value: "facebook",
      icon: <Iconify icon={"AcUnitOutlined"} width={24} height={24} />,
    },
    {
      value: "instagram",
      icon: <Iconify icon={"AcUnitOutlined"} width={24} height={24} />,
    },
    {
      value: "viber",
      icon: <Iconify icon={"AcUnitOutlined"} width={24} height={24} />,
    },
    {
      value: "linkedin",
      icon: <Iconify icon={"AcUnitOutlined"} width={24} height={24} />,
    },
    {
      value: "skype",
      icon: <Iconify icon={"AcUnitOutlined"} width={24} height={24} />,
    },
    {
      value: "tiktok",
      icon: <Iconify icon={"AcUnitOutlined"} width={24} height={24} />,
    },
    {
      value: "pinterest",
      icon: <Iconify icon={"AcUnitOutlined"} width={24} height={24} />,
    },
    {
      value: "gplus",
      icon: <Iconify icon={"AcUnitOutlined"} width={24} height={24} />,
    },
    {
      value: "twitter",
      icon: <Iconify icon={"AcUnitOutlined"} width={24} height={24} />,
    },
    {
      value: "youtube",
      icon: <Iconify icon={"AcUnitOutlined"} width={24} height={24} />,
    },
  ];

  const defaultValues = Object.fromEntries(
    SOCIAL_LINKS.map((link) => [link?.value, socials?.[link.value] || ""])
  );

  const methods = useForm({
    defaultValues: defaultValues,
    values: socials,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: any) => {
    await dispatch(updateSocialLinkData({ ...data, id: socials.id })).then(
      (res: any) => {
        if (res?.error) {
          dispatch(
            showToast({
              message: "Update Failed",
              type: "warning",
            })
          );
        } else {
          dispatch(
            showToast({
              message: "Updated Successfully",
              type: "success",
            })
          );
        }
      }
    );
    // .catch((error) => {
    //   dispatch(
    //     showToast({
    //       message: "Updated Successfully",
    //       type: "success",
    //     })
    //   );
    // });
  };

  return (
    <Page title="Social Settings">
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Social Settings
        </Typography>
        <Card sx={{ p: 3 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container justifyContent="flex-end" spacing={3}>
              {SOCIAL_LINKS?.map((link: any, index: any) => (
                <Grid item xs={6} sm={6} md={6} key={index}>
                  <RHFTextField
                    label={link?.value}
                    name={link?.value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {link.icon}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ))}

              <Grid item>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Update
                </LoadingButton>
              </Grid>
              {/* </Stack> */}
            </Grid>
          </FormProvider>
        </Card>
      </Container>
      {/* <DevTool control={methods.control} /> */}
    </Page>
  );
}
