import { Navigate, createBrowserRouter } from "react-router-dom";
import { LoginModule } from "../auth/Login/index";
import { index as DashboardApp } from "../_app/pages/DashboardApp";
import Page404 from "../_app/pages/404";
import { publicRoutes, privateRoutes } from "../_helpers/constants/routes";
import PrivateRoutes from "./ProtectedRoute";
import PublicRoutes from "./PublicRoutes";
import SiteSettings from "../_app/pages/Settings/SiteSettings";
import SocialSettings from "../_app/pages/Settings/SocialSettings";
import MailListing from "../_app/pages/MailListing";

export const appRoutes = createBrowserRouter([
  {
    path: publicRoutes[404],
    element: <Page404 />,
  },
  { path: publicRoutes.ANY, element: <Navigate to={`${publicRoutes[404]}`} /> },
  {
    path: privateRoutes.INDEX,
    element: <PublicRoutes />,
    children: [
      {
        path: publicRoutes.LOGIN,
        element: <LoginModule />,
      },
    ],
  },
  {
    path: privateRoutes.DASHBOARD,
    element: <PrivateRoutes />,
    children: [
      {
        path: privateRoutes.GENERAL.HOME,
        element: <DashboardApp />,
        index: true,
      },
      {
        path: privateRoutes.SETTINGS.ROOT,
        children: [
          {
            path: privateRoutes.SETTINGS.SITE_SETTINGS,
            element: <SiteSettings />,
            index: true,
          },
          {
            path: privateRoutes.SETTINGS.SOCIAL_SETTINGS,
            element: <SocialSettings />,
          },
        ],
      },
      {
        path: privateRoutes.MAIL.ROOT,
        element: <MailListing />,
        index: true,
      },
    ],
  },
]);
