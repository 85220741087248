import { Outlet, Navigate, useLocation } from "react-router-dom";
import DashboardLayout from "../layout/dashboard/DashboardLayout";
import { privateRoutes, publicRoutes } from "../_helpers/constants/routes";
import { useSelector } from "react-redux";
import { RootState } from "../_store";

export default function PrivateRoutes() {
  const { pathname } = useLocation();
  const { isAuthenticated } = useSelector((state: RootState) => state?.auth);
  if (!isAuthenticated) {
    return <Navigate to={publicRoutes.LOGIN} />;
  }
  if (pathname === privateRoutes.DASHBOARD) {
    return <Navigate to={privateRoutes.GENERAL.HOME} />;
  }
  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
}
