import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import currentUserReducer from "./slice/currentUser.slice";
import authReducer from "./slice/auth.slice";
import toastReducer from "./slice/toast.slice";
import socialDataReducer from "./slice/socialLinks.slice";
import connectWithUsReducer from "./slice/connectWithUs.slice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["auth"],
};

const reducer = combineReducers({
  toast: toastReducer,
  auth: authReducer,
  currentUser: currentUserReducer,
  socialLinks: socialDataReducer,
  mail: connectWithUsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
