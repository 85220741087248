import Label from "../../../_components/Label";
import SvgIconStyle from "../../../_components/SvgIconStyle";
import { privateRoutes } from "../../../_helpers/constants/routes";
import { PATH_DASHBOARD } from "../../../routes/paths";
// import { PATH_DASHBOARD } from "../../../routes/paths";

const getIcon = (name: any) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
};

const navConfig = [
  {
    subheader: "general",
    items: [
      {
        title: "dashboard",
        path: privateRoutes.GENERAL.HOME,
        icon: ICONS.dashboard,
      },
    ],
  },
  {
    subheader: "app",
    items: [
      {
        title: "inbox",
        path: privateRoutes.MAIL.ROOT,
        icon: ICONS.mail,
        info: (
          <Label variant="outlined" color="error">
            +32
          </Label>
        ),
      },
    ],
  },
  {
    subheader: "Management",
    items: [
      {
        title: "Settings",
        path: privateRoutes.SETTINGS.ROOT,
        icon: ICONS.user,
        children: [
          {
            title: "Site Settings",
            path: privateRoutes.SETTINGS.SITE_SETTINGS,
            icon: ICONS.invoice,
          },
          {
            title: "Social Settings",
            path: privateRoutes.SETTINGS.SOCIAL_SETTINGS,
            icon: ICONS.invoice,
          },
        ],
      },
    ],
  },
];

export default navConfig;
