import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Divider,
  Button,
  Grid,
  useMediaQuery,
  Box,
} from "@mui/material";
import LoginForm from "./LoginForm";

const LoginInner = () => {
  const theme = useTheme<any>();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">
              Sign in with Email address
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <LoginForm />
    </>
  );
};

export default LoginInner;
