import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchUserData } from "../action/currentUser.action";
import { AlertColor } from "@mui/material";

interface toastState {
  message?: string | null;
  type?: AlertColor;
  showToast?: boolean;
}

const initialState: toastState = {
  message: null,
  type: undefined,
  showToast: false,
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast: (state: any, action: PayloadAction<toastState>) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.showToast = true;
    },
    hideToast: (state: any, action: PayloadAction<toastState>) => {
      state.showToast = action.payload.showToast || false;
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;

export default toastSlice.reducer;
